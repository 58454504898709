import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";

interface Ticket {
  id: number;
  subject: string;
  status: string;
  updatedAt: string;
}

const ListTickets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
    // eslint-disable-next-line
  }, [page, showAll]);

  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `help/my-tickets?page=${page}&pageSize=${pageSize}&all=${showAll}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        setTickets(response.tickets);
        setTotalCount(response.totalCount);
      }
    } catch (error) {
      console.error("There has been a problem fetching tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
    setPage(1); // reset to first page when toggling
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleRowClick = (ticketId: number) => {
    navigate(`/help/ticket/${ticketId}`);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center m-3">
        <h1>My Tickets</h1>
        <Link to="/help/new" className="btn btn-primary">
          + Create New Ticket
        </Link>
      </div>

      <div className="mb-3">
        <label>
          <input
            type="checkbox"
            checked={showAll}
            onChange={handleToggleShowAll}
          />
          {" Show all tickets (including older closed ones)"}
        </label>
      </div>

      {tickets.length === 0 ? (
        <p>No tickets found.</p>
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Subject</th>
              <th>Status</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {tickets.map((ticket) => (
              <tr
                key={ticket.id}
                style={{ cursor: "pointer" }}
                onClick={() => handleRowClick(ticket.id)}
              >
                <td>{ticket.id}</td>
                <td className="text-primary text-decoration-underline">
                  {ticket.subject}
                </td>
                <td>{ticket.status}</td>
                <td>{new Date(ticket.updatedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>

        <span>
          Page {page} of {totalPages}
        </span>

        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ListTickets;
