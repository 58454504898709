import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";

interface TicketComment {
  id: number;
  body: string;
  authorId: number;
  createdAt: string;
}

interface TicketDetails {
  id: number;
  subject: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  comments: TicketComment[];
}

const TicketDetails = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (ticketId) {
      fetchTicketDetails(ticketId);
    }
    // eslint-disable-next-line
  }, [ticketId]);

  const fetchTicketDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await callApi(`help/ticket/${id}`, HttpMethod.GET);
      if (response) {
        setTicket(response);
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || !ticket) {
    return <Loading />;
  }

  return (
    <div className="container mt-4">
      <div className="">
        <h2>Ticket #{ticket.id}</h2>
        <p>
          <strong>Subject:</strong> {ticket.subject}
        </p>
        <p>
          <strong>Status:</strong> {ticket.status}
        </p>
        <p>
          <strong>Created At:</strong>{" "}
          {new Date(ticket.createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Last Updated:</strong>{" "}
          {new Date(ticket.updatedAt).toLocaleString()}
        </p>
        <hr />
        <h4>Conversation</h4>
        {ticket.comments.length === 0 ? (
          <p>No comments found.</p>
        ) : (
          <ul className="list-group">
            {ticket.comments.map((comment) => (
              <li key={comment.id} className="list-group-item">
                <p
                  dangerouslySetInnerHTML={{
                    __html: comment.body.replace(/\n/g, "<br />"),
                  }}
                />
                <small>
                  Posted by User #{comment.authorId} on{" "}
                  {new Date(comment.createdAt).toLocaleString()}
                </small>
              </li>
            ))}
          </ul>
        )}
      </div>
      <button
        className="btn btn-secondary mb-3"
        onClick={() => navigate("/help/tickets")}
      >
        ← Back to My Tickets
      </button>
    </div>
  );
};

export default TicketDetails;
