import { useNavigate } from "react-router-dom";
import Styles from "./HelpButton.module.css";

const HelpButton = () => {
  const navigate = useNavigate();
  const handleHelpClick = () => {
    navigate("/help/new");
  };

  return (
    <>
      <button
        className={`btn nav-link text-white nav-link ${Styles["help-button"]}`}
        id="bd-theme"
        type="button"
        data-bs-display="static"
        aria-label="Help"
        onClick={handleHelpClick}
      >
        <i className="bi bi-question-circle"></i>
        <span className="ms-1" id="help-text">
          Help
        </span>
      </button>
    </>
  );
};
export default HelpButton;
